import _ from "lodash";
import Promise from "bluebird";
import Backbone from "backbone";
import HybridPageableMediaCollection from "scripts/collections/hybridPageableMediaCollection";
import AnthologyCollectionContentCountModel from "scripts/models/anthologyCollectionContentCountModel";
import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import PagerView from "scripts/views/ellipsisPagerView";
import StackedMediaTypesChartView from "scripts/views/stackedMediaTypesChartView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import mediaPageContentSubPageTranslations from "../../i18n/mediaPageContentSubPageTranslations";

import templateAnthologyCollectionPageContentSubPage from "templates/anthologyCollectionPageContentSubPage.hbs";
import { getContentCount } from "../utils/mediaHelpers";
import querystring from "querystring";

// const PAGE_SIZE = 100;
const PAGE_SIZE = 20;
const DEFAULT_SORT = "title asc";

const chan = Backbone.Radio.channel;

class AnthologyCollectionPageContentSubPageView extends BaseView {
  get template() {
    return templateAnthologyCollectionPageContentSubPage;
  }

  constructor(options) {
    super(options);

    this.mediaModel = options.mediaModel;

    this.anthologyCollectionContentCountModel = new AnthologyCollectionContentCountModel({
      id: this.model.get("anthologyCollectionId"),
    });

    this.mediaPageContentSubPageTranslations = new mediaPageContentSubPageTranslations();

    const anthologyCollectionId = this.model.get("anthologyCollectionId");
    const contentTypeUrlFragment = this.contentTypeUrlFragment(this.model.get("contentType"));

    const pageIndex = this.model.get("pageIndex");

    this.collection = new HybridPageableMediaCollection([], {
      path: `/collections/${anthologyCollectionId}/content${contentTypeUrlFragment}`,
      state: {
        currentPage: pageIndex,
        pageSize: PAGE_SIZE,
      },
    });

    /**
     * Hack to satisfy Backbone.PageableCollection._checkState
     * if starting from a page other than 0.
     */
    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }

    this.stackedMediaTypesChartView = this.addSubView(
      new StackedMediaTypesChartView({
        model: new Backbone.Model(),
      }),
    );

    chan("anthologyCollectionPageNav").on(
      "sortOptionChange",
      sortOption => {
        chan("display").trigger("bodyViewLoading");
        this.collection.queryParams = {
          limit: PAGE_SIZE,
          offset: 0,
          sort: sortOption,
        };
        this.model.set("contentSort", sortOption);
        this.model.set("pageIndex", 0);

        this.collection
          .fetch()
          .cancellable()
          .then(() => {
            chan("display").trigger("bodyViewLoaded");
            redirectToFragment(this.urlFragment());
          });
      },
      this,
    );
  }

  createContentView() {
    const mediaCount = getContentCount(
      this.model.get("contentType"),
      this.anthologyCollectionContentCountModel.toJSON(),
    );
    const totalPages = Math.ceil(mediaCount / PAGE_SIZE);

    let pageViewOptions = {
      hasPreviousPage: this.collection.hasPreviousPage(),
      hasNextPage: this.collection.hasNextPage(),
      pageNumber: this.model.get("pageIndex") + 1,
      totalPages: totalPages,
    };

    let presentationType = this.model.get("presentationType");
    const collection = { collection: this.collection };
    const mediaView =
      presentationType === "list" ? new MediaListView(collection) : new MediaSalvattoreGridView(collection);

    let subPage = new PagerView(_.extend({ mediaView }, pageViewOptions));

    subPage.on(
      "pageSequenceChanged",
      pageNumber => {
        this.model.set("pageIndex", pageNumber - 1);
        redirectToFragment(this.urlFragment());
      },
      this,
    );

    return subPage;
  }

  render() {
    this.$el.html(this.template(this.getTemplateData()));

    this.stackedMediaTypesChartView.model.set(this.mediaTypePercentages());

    this.stackedMediaTypesChartView
      .appendTo($("#bb-anthology-collection-page-content-sub-page-stacked-media-types-chart-region"))
      .render();

    this.contentView.appendTo($("#bb-anthology-collection-page-content-sub-page-content-region")).render();

    this.setActiveFilter();

    return this;
  }

  getTemplateData() {
    const model = this.model.toJSON();
    const contentCountModel = this.anthologyCollectionContentCountModel.toJSON();
    const mediaModel = this.mediaModel.toJSON();

    const templateData = _.merge(
      {
        i18n: this.mediaPageContentSubPageTranslations.getTranslations({
          modelMediaType: "collection",
          mediaCountModel: contentCountModel,
        }),
      },
      model,
      contentCountModel,
      mediaModel,
    );

    return templateData;
  }

  setActiveFilter() {
    const contentType = this.model.get("contentType");

    if (contentType === "all-content") {
      $("#bb-anthology-collection-page-content-sub-page-all-content-link")
        .addClass("active")
        .attr("aria-current", "page");
    } else {
      let presentationType = this.model.get("presentationType");
      let anthologyId = this.model.get("anthologyId");
      let anthologyCollectionId = this.model.get("anthologyCollectionId");
      let contentSort = this.model.get("contentSort");
      let href = `"/anthology-collection/${anthologyId}/${anthologyCollectionId}/${presentationType}/${contentType}?sort=${contentSort}"`;
      this.$el
        .find("a[href=" + href + "].content-filter")
        .addClass("active")
        .attr("aria-current", "page");
    }
  }

  percent(num, divisor) {
    return (num / divisor) * 100;
  }

  mediaTypePercentages() {
    let allContent = this.anthologyCollectionContentCountModel.get("allContentCount");

    let written = this.anthologyCollectionContentCountModel.get("articleCount");
    written += this.anthologyCollectionContentCountModel.get("bookCount");
    written += this.anthologyCollectionContentCountModel.get("otherDocumentCount");
    written += this.anthologyCollectionContentCountModel.get("chapterCount");

    let image = this.anthologyCollectionContentCountModel.get("imageCount");
    let audio = this.anthologyCollectionContentCountModel.get("audioCount");
    let video = this.anthologyCollectionContentCountModel.get("videoCount");

    return {
      writtenPercent: this.percent(written, allContent),
      imagePercent: this.percent(image, allContent),
      audioPercent: this.percent(audio, allContent),
      videoPercent: this.percent(video, allContent),
    };
  }

  async sync() {
    // first fetch mediaModel then collection so that defaultContentSort is available to syncCollection()
    await Promise.all([this.anthologyCollectionContentCountModel.fetch(), this.mediaModel.fetch()]);
    await this.syncCollection();

    this.contentView = this.addSubView(this.createContentView());
  }

  syncCollection() {
    this.collection.state.currentPage = this.model.get("pageIndex");
    const contentSort = this.model.get("contentSort");
    const defaultContentSort = this.mediaModel.get("defaultContentSort");

    this.collection.queryParams = {
      limit: PAGE_SIZE,
      offset: this.collection.state.currentPage * this.collection.state.pageSize,
      sort: contentSort || defaultContentSort || DEFAULT_SORT,
    };

    this.model.set("contentSort", this.collection.queryParams.sort);
    chan("anthologyCollectionPageContentSubPage").trigger("sortOptionChange", this.collection.queryParams.sort);

    return this.collection
      .fetch()
      .cancellable()
      .then(o => o);
  }

  contentTypeUrlFragment(contentType) {
    if (contentType === "highlights") {
      return "?collectionHighlight=1";
    } else if (contentType === "all-content") {
      return "";
    } else {
      return `/${contentType}`;
    }
  }

  urlFragment() {
    const anthologyId = this.model.get("anthologyId");
    const anthologyCollectionId = this.model.get("anthologyCollectionId");
    const presentationType = this.model.get("presentationType");
    const contentType = this.model.get("contentType");
    const pageNumber = this.model.get("pageIndex") + 1;
    const sort = { sort: this.model.get("contentSort") };

    return `anthology-collection/${anthologyId}/${anthologyCollectionId}/${presentationType}/${contentType}/${pageNumber}?${querystring.stringify(
      sort,
    )}`;
  }
}

export default AnthologyCollectionPageContentSubPageView;
